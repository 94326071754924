<template>
	<div>
		<nav class="bg-light fixed-top nav wid">
			<div class="container">
				<a href="#">
					<!-- <img src="../assets/logo (3).png" alt="" /> -->
				</a>
				<h1>康喜药多多</h1>
				<div class="btns">
					<ul>
						<router-link to="/index" class="link" active-class="active">
							<li>首页</li>
						</router-link>
						<router-link to="/productcenter" class="link" active-class="active">
							<li>产品中心</li>
						</router-link>
						<router-link to="/companyprofile" class="link" active-class="active">
							<li>公司简介</li>
						</router-link>
						<!--  <router-link to="/newscenter" class="link" active-class="active">
              <li>新闻中心</li></router-link
            > -->
						<!--   <router-link to="/information" class="link" active-class="active">
              <li>招聘信息</li></router-link
            > -->
						<router-link to="/contact" class="link" active-class="active">
							<li>联系我们</li>
						</router-link>
					</ul>
				</div>
			</div>
		</nav>
		<div style="height: 71px"></div>
		<router-view></router-view>
		<footer class="footer wid">
			<div class="footer-total">
				<div class="footercotent">
					<p class="contenttitle">康喜药多多</p>
					<p class="pwidth">
						<!-- 公司总部：四川省成都市金牛区量力医药健康城7号楼15层 -->
					</p>
					<!-- <p class="pwidth">仓储基地：四川省成都市温江区金府路中段51号</p> -->
				</div>
				<div class="footercotent">
					<p class="contenttitle">联系我们</p>
					<p class="pwidth">电话：0871-68168405</p>
					<!-- <p class="pwidth">邮箱：jpfyy618@163.com</p> -->
				</div>
			</div>

			<div class="footer-title">
				<span>
					<span> ICP备案证书号:</span>
					<span class="title2"  @click="Interlinking">滇ICP备2022000574号-1</span>
				</span>
				<!-- <span class="title2" style="color: #288ef6">滇ICP备2021000291号</span> -->
			</div>
		</footer>
	</div>
</template>
<script>
	export default {
		name: "home",
		data() {
			return {}
		},
		methods: {
			Interlinking() {
				let url = "http://beian.miit.gov.cn"
				window.open(url, '_blank') // 新窗口打开外链接
			}
		}

	};
</script>
<style lang='scss' scoped>
	.footer-total {
		width: 960px;
		margin: 0 auto;
		display: flex;
		margin-top: 20px;
		text-align: left;

		.footercotent {
			margin-right: 174px;
		}

		.contenttitle {
			font-size: 22px;
		}
	}

	.footer-title {
		margin-top: 115px;
		.title2 {
			cursor: pointer;
			color: #ffffff;
			font-size: 15px;
		}
	}

	.pwidth {
		width: 302px;
		font-size: 16px;
		font-family: DengXian;
		font-weight: bold;
		color: #e6e6e6;
		margin-top: 30px;
	}

	.fixed-top {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}

	.nav {
		padding: 7px 14px;
		height: 108px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.container {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.btns {
		flex-grow: 1;
		display: flex;
		justify-content: flex-end;
	}

	ul {
		display: flex;
		list-style: none;
		color: #666;
		font-size: 14px;
	}

	li {
		padding: 5px 10px;
		cursor: pointer;
	}

	li:hover {
		color: #212529;
	}

	.active {
		color: #fe2a1e !important;
		font-weight: bolder;
	}

	.footer {
		// margin-top: 14px;
		padding-top: 42px;
		background: #353c44;
		height: 390px;
		color: #fff;
	}

	.row {
		margin-bottom: 42px;
		padding: 0 15px;
		width: 100%;
	}

	.ul {
		font-size: 16px;
		justify-content: space-around;
	}

	.content {
		display: flex;
	}

	.inclues {
		flex-grow: 1;
	}

	.inclues div {
		margin-bottom: 7px;
		text-align: left;
		font-size: 14px;
	}

	.inclues .head {
		font-size: 16px;
	}

	.foot {
		border-top: 1px solid #dee2e6;
		line-height: 3;
		font-size: 14px;
		color: #ff0000;
	}

	.foot div:last-child {
		color: #6c757d;
	}
</style>
